import React from 'react';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'components';
import { AppSchemeENUM } from 'utils/enums/appENUM';
import { useAppContext } from 'utils/context/AppContext';

const AccessDenied: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const { app } = useAppContext();
	return (
		<div className={styles.container}>
			<div className={styles.container_elements}>
				<div className='bg-primary-500 rounded-xl p-4'>
					<Icon name="lock" color='white' size={50} />
				</div>
				<h1 className='text-2xl'>Access Denied</h1>
				<p>A site membership is required to view this page.</p>
				<div className="flex gap-6">
					<Button onClick={() => app.scheme === AppSchemeENUM.IDENTITY_MATRIX ? navigate("/pricing") : navigate("/signup")} type="outline">Sign Up</Button>
					<Button onClick={() => navigate("/signin")}>Log In</Button>
				</div>
			</div>
		</div>
	);
};
export default AccessDenied;